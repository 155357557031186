import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { escapeRegExp } from 'lodash';
import { Card, Flex } from 'core/components';
import { Field, Form, Select } from 'core/form';
import { getOption } from 'core/form/components/modalSelect/selectHelpers';
import storeLoader from 'app/stores/storeLoader';
import {
  getDensityOptions,
  metricListForMeshOptions,
  DEFAULT_FILTERED_METRICS
} from 'app/views/synthetics/components/mesh/util/constants';
import {
  DensityOptionRenderer,
  MetricOptionRenderer,
  MetricValueRenderer
} from 'app/views/synthetics/components/mesh/toolbar/renderers';
import AgentFilterField from './AgentFilterField';

const fields = {
  highlightedRow: { placeholder: 'Source Agent' },
  highlightedCol: { placeholder: 'Target Agent' },
  filteredMetrics: {
    options: metricListForMeshOptions,
    defaultValue: DEFAULT_FILTERED_METRICS
  },
  density: {
    options: getDensityOptions()
  }
};

@withTheme
@Form({ fields })
@inject('$syn')
@storeLoader('$syn.agents')
@observer
export default class Toolbar extends Component {
  constructor(props) {
    super(props);
    const { form, highlightedRowAgent, highlightedColAgent, metrics } = props;

    if (metrics) {
      form.setValue('filteredMetrics', metrics);
    }

    if (highlightedRowAgent) {
      form.setValue('highlightedRow', highlightedRowAgent);
    }

    if (highlightedColAgent) {
      form.setValue('highlightedCol', highlightedColAgent);
    }
  }

  static getDerivedStateFromProps(props) {
    const { $syn, form, density, mesh } = props;

    form.setValue('density', density.id);

    return {
      agentOptions: mesh.map((item) => {
        const agentModel = $syn.agents.get(item.agent_id);
        return {
          label: item.alias,
          value: item.agent_id,
          meshItem: item,
          siteDisplayName:
            agentModel?.siteDisplayName && agentModel.siteDisplayName !== '---'
              ? agentModel.siteDisplayName
              : item.name,
          agentModel
        };
      })
    };
  }

  state = {
    agentOptions: []
  };

  handleChange = () => {
    const { form, onToolbarChange } = this.props;
    const { agentOptions } = this.state;
    const formValues = form.getValues();

    if (onToolbarChange) {
      const densityField = form.getField('density');
      const matchingDensityOption = getOption(densityField.options, formValues.density);
      const matchingSourceAgentOption = getOption(agentOptions, formValues.highlightedRow);
      const matchingTargetAgentOption = getOption(agentOptions, formValues.highlightedCol);

      onToolbarChange({
        ...formValues,
        density: matchingDensityOption.density || formValues.density,
        highlightedRowAgent: formValues.highlightedRow,
        highlightedColAgent: formValues.highlightedCol,
        highlightedRow: matchingSourceAgentOption?.meshItem?.index,
        highlightedCol: matchingTargetAgentOption?.meshItem?.index
      });
    }
  };

  get metricOptionRenderer() {
    const { metrics } = this.props;

    return (option) => {
      const { selected } = option;
      // you can't deselect the last entry, there must be at least one
      const isSelectable = !(metrics?.length === 1 && selected === true);

      return MetricOptionRenderer({ isSelectable, ...option });
    };
  }

  get densityOptionRenderer() {
    const { theme } = this.props;

    return (option) => DensityOptionRenderer({ theme, ...option });
  }

  handleAgentsFilter = async (searchTerm) => {
    const { agentOptions } = this.state;

    if (!searchTerm) {
      return agentOptions;
    }

    return agentOptions.filter((option) => {
      const { meshItem, agentModel } = option;
      const { locationInfo, asFormatted } = agentModel;
      const searchSource = `${meshItem.agent_id} ${meshItem.alias} ${locationInfo} ${asFormatted}`;
      const re = new RegExp(escapeRegExp(searchTerm), 'i');

      return re.test(searchSource);
    });
  };

  render() {
    const { form } = this.props;

    return (
      <Card p={1} mb={1} gap={1} display="flex" justifyContent="space-between">
        <Flex gap={1}>
          <AgentFilterField
            field={form.getField('highlightedRow')}
            onQuery={this.handleAgentsFilter}
            onChange={this.handleChange}
          />
          <AgentFilterField
            field={form.getField('highlightedCol')}
            onQuery={this.handleAgentsFilter}
            onChange={this.handleChange}
          />
          <Field name="filteredMetrics" onChange={this.handleChange} mb={0}>
            <Select
              menuWidth={200}
              valueRenderer={MetricValueRenderer}
              optionRenderer={this.metricOptionRenderer}
              multi
              renderAsButton
              toggle
              keepOpen
            />
          </Field>
        </Flex>

        <Field name="density" onChange={this.handleChange} mb={0}>
          <Select
            menuWidth={180}
            valueRenderer={this.densityOptionRenderer}
            optionRenderer={this.densityOptionRenderer}
          />
        </Field>
      </Card>
    );
  }
}
