export const DENSITY = {
  MAX: { id: 'MAX', name: 'Max', size: 10, labelWidth: 0 },
  HIGH: { id: 'HIGH', name: 'High', size: 16, labelWidth: 20 },
  LOW: { id: 'LOW', name: 'Regular', size: 40, labelWidth: 100 }
};

export const CELL_MARGIN = 8;

/*
  Options for a select control of density options
*/
export function getDensityOptions() {
  return ['LOW', 'HIGH', 'MAX'].map((densityId) => {
    const density = DENSITY[densityId];
    return { label: `${density.name} Density`, value: density.id, density };
  });
}

/*
  Returns the cell size of the provided density configuration, falling back to the largest size if necessary
*/
export function getDensityCellSize(density) {
  if (density) {
    return density.size;
  }

  console.warn('Density was not provided to the mesh cell, using the largest possible size instead');
  return DENSITY.LOW.size;
}

/*
  Provides a recommended density based on mesh size

  <= 25 - low
  26-50 - mediumn
  >50 - high
*/
export function getDensityFromMeshSize(mesh) {
  const meshSize = mesh?.length || 0;

  if (meshSize <= 25) {
    return DENSITY.LOW;
  }

  if (meshSize > 25 && meshSize <= 50) {
    return DENSITY.HIGH;
  }

  return DENSITY.MAX;
}

export const metricListForMeshOptions = [
  { label: 'Latency', value: 'latency' },
  { label: 'Packet Loss', value: 'loss' },
  { label: 'Jitter', value: 'jitter' }
];

export const DEFAULT_FILTERED_METRICS = metricListForMeshOptions.map(({ value }) => value);
