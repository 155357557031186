/*
  a group of reusable renderers for common columns in tables of alarms
  these renderers are currently set up to support a mixture of v1 and v2 alarms
*/
import { FiBell } from 'react-icons/fi';
import React from 'react';
import moment from 'moment';
import { dateTimeFormatter } from 'core/util/dateUtils';
import { Box, ButtonLink, CopyText, Flex, Grid, Icon, Link, Tag, Text, Tooltip } from 'core/components';
import { CONDITION_TYPE_LABELS } from 'shared/synthetics/bgp';
import { isAlarmActive, getFirstTriggerTime } from 'shared/synthetics/utils';
import { SEVERITY_MAP, ALARM_STATE_RAW_TO_KEY, ALARM_NULL_END_DATE } from 'shared/alerting/constants';
import { getTestTypeLabel, healthToIntent } from 'app/views/synthetics/utils/syntheticsUtils';
import TestErrorPopover from 'app/views/synthetics/TestErrorPopover';
import AgentRenderer from 'app/views/synthetics/components/AgentRenderer';
import SeverityIcon from 'app/views/synthetics/components/incidentLogs/SeverityIcon';

function getTestResultsLink({ $syn, test, agentId, targetAgentId, target, startTime, endTime, dateRangeOnly = false }) {
  let testUrl;
  let display;

  if (test) {
    testUrl = `/v4/synthetics/tests/${test.id}/results`;
    display = test.get('display_name');

    if (agentId && !dateRangeOnly) {
      testUrl += `/agent/${agentId}`;
      const agent = $syn.agents.get(agentId);
      if (agent) {
        display += ` - ${agent.get('agent_alias')}`;
      }

      if (target && !test.isPageLoad && !test.isTransaction) {
        // Mesh tests and A2A tests now use the target_agent_id in the url param for subtest page
        if (test.isMesh || test.isAgentTest) {
          testUrl += `/${targetAgentId}`;
        } else if (!test.isHostname) {
          // hostname tests don't have target in the subtest url anymore
          testUrl += `/${target}`;
        }
        display += ' to ';
        if (targetAgentId) {
          const targetAgent = $syn.agents.get(targetAgentId);
          if (targetAgent) {
            display += targetAgent.get('agent_alias');
          }
        } else {
          display += target;
        }
      }
    }

    // the test results page state subtracts 15 minutes from this param
    const start = moment.utc(startTime).startOf('minute').unix(); // drop down to the nearest whole minute
    const rawEnd = endTime;
    const thirtyMinutesFromStart = moment.utc(startTime).add(30, 'minutes').unix();
    let end = null;

    if (!rawEnd || rawEnd === ALARM_NULL_END_DATE) {
      // if the end is undefined in either v1 or v2 terms (alarm is active), use the calculated 30 mins from start time value
      // but make sure to take the lower of that value and the current time to prevent populating a date picker with an end date in the future
      end = Math.min(moment.utc().unix(), thirtyMinutesFromStart);
    } else {
      // otherwise, take the lower of the 30 mins from start or end time
      // the test results page state adds 15 minutes to this param
      end = Math.min(
        moment.utc(endTime).unix(),
        // we want it to be no more than 1 hour of data so we get full granularity
        // so cap it at +30 minutes since the page will automatically add another 15 minutes on both sides.
        thirtyMinutesFromStart
      );
    }

    if (dateRangeOnly && start === test.results.startDate && end === test.results.endDate) {
      // don't show a link when we already have this date range selected in test results
      return { testUrl: null, display, title: 'Date range is currently selected' };
    }

    return { testUrl: `${testUrl}?start=${start}&end=${end}`, display };
  }

  return { testUrl, display };
}

function renderTestResultsLink({ $syn, model, value, startTime, dateRangeOnly = false }) {
  const { testId, agentId, targetAgentId } = model.get('key.value') || {};
  const test = testId && $syn.tests.get(testId);
  const { target, resolver } = model.get('details') || {};

  const { testUrl, title } = getTestResultsLink({
    $syn,
    test,
    agentId,
    targetAgentId,
    target: test.isDns ? resolver : target,
    startTime,
    endTime: model.get('end_time'),
    dateRangeOnly
  });

  return (
    <CopyText text={value} textProps={{ ellipsis: true }}>
      {testUrl ? (
        <Link to={testUrl} fontWeight="bold">
          {value}
        </Link>
      ) : (
        <Text title={title}>{value}</Text>
      )}
    </CopyText>
  );
}

function agentRenderer({ $syn }) {
  return ({ model }) => {
    const agentId = model.get('key.value.agentId');
    const testId = model.get('key.value.testId');
    const test = testId && $syn.tests.get(testId);

    if (agentId) {
      const agent = $syn.agents.get(agentId);

      if (agent) {
        return <AgentRenderer agent={agent} showLocationInfo />;
      }
    }

    return (
      <Text fontStyle="italic" muted>
        N/A
        {test && test.isPerTestAlerting && (
          <Tooltip
            content={
              <Text as="div" width={180}>
                Test-based alert settings do not track agent-based data
              </Text>
            }
            minimal
          >
            <Icon ml={1} icon="info-sign" color="primary" />
          </Tooltip>
        )}
      </Text>
    );
  };
}

export function alarmStateRenderer({ minimal = false, raw = false } = {}) {
  return ({ value }) => {
    const isAlarm = isAlarmActive(value);
    const label = isAlarm ? 'Active' : 'Cleared';

    if (raw) {
      return label;
    }

    if (minimal) {
      const iconProps = isAlarm ? { icon: 'notifications', color: 'kentik' } : { icon: 'tick', intent: 'success' };
      return <Icon {...iconProps} />;
    }

    return <Tag minimal>{label}</Tag>;
  };
}

function endTimeRenderer({ model }) {
  const value = model.get('endTime') || model.get('end_time');
  const state = model.get('state');
  const isAlarm = isAlarmActive(state);
  return isAlarm ? <Text muted>N/A</Text> : dateTimeFormatter({ value });
}

export function durationRenderer({ model }) {
  const state = model.get('state');
  const isAlarm = state === 'alarm' || ALARM_STATE_RAW_TO_KEY[state] === 'alarm';
  const firstTriggerTime = getFirstTriggerTime(model.get('event_start_time'));
  const start = dateTimeFormatter({ value: firstTriggerTime || model.get('start_time') });
  const end = isAlarm ? 'now' : dateTimeFormatter({ value: model.get('end_time') });

  return `${start} to ${end}`;
}

export function severityRenderer({ minimal = false, raw = false } = {}) {
  return ({ model, value }) => {
    let intent = 'warning';
    let label = 'warning';

    if (value === SEVERITY_MAP.CRITICAL.ALERT_MANAGER || value === SEVERITY_MAP.CRITICAL.STANDARD_ALERT) {
      intent = 'danger';
      label = 'critical';
    }

    if (raw) {
      return label;
    }

    if (minimal) {
      return <SeverityIcon severity={label} />;
    }

    return (
      <Tag
        fontWeight="bold"
        textTransform="capitalize"
        intent={intent}
        minimal
        icon={isAlarmActive(model.get('state')) && FiBell}
      >
        {label}
      </Tag>
    );
  };
}

function makeCollectionConfig({ $syn, minimal = false, testResults = false, bgp = false, setPrefixes } = {}) {
  return {
    columns: {
      agent: {
        id: 'agent',
        label: 'Agent',
        sortField: 'agent',
        minWidth: 180,
        ellipsis: false,
        renderer: agentRenderer({ $syn })
      },

      alarm: {
        label: 'Alert ID',
        name: 'id',
        width: 300,
        renderer: ({ model, value }) => {
          if (testResults) {
            const { testId } = model.get('key.value') || {};
            const test = $syn.tests.get(testId);

            if (test && test.isPerAgentAlerting) {
              // when alerting is per-agent, we will have a subtest details view to route to
              // note that in the very unlikely case that an account has flipped between per-[test|agent] alerting
              // we might be in a situation where the alarm was generated in a per-test config but is now in a per-agent config (we're alright with that)
              return renderTestResultsLink({
                $syn,
                model,
                value,
                // favor using the first trigger as the starting point, allowing fallback to the alarm start
                startTime: getFirstTriggerTime(model.get('event_start_time')) || model.get('start_time')
              });
            }
          }

          return <CopyText text={value} textProps={{ ellipsis: true }} />;
        }
      },

      bgpDisplay: {
        label: 'Type',
        name: 'details.bgp.display',
        width: 150,
        renderer: ({ model, value }) => {
          const subtest = model.get('key.value.subtest');

          if (subtest) {
            return CONDITION_TYPE_LABELS[subtest] || 'Unknown';
          }

          return (
            <Box>
              <Text as="div">{value}</Text>
            </Box>
          );
        }
      },

      bgpPrefix: {
        label: 'Prefix',
        name: 'details.bgp.prefix',
        width: 200,
        renderer: ({ value }) => {
          if (!value || !setPrefixes) {
            return '-';
          }

          return (
            <Box>
              <ButtonLink intent="primary" onClick={() => setPrefixes(value)} minimal small>
                {value}
              </ButtonLink>
            </Box>
          );
        }
      },

      bgpReason: {
        label: 'Details',
        name: 'details.bgp.reason',
        renderer: ({ value }) => {
          if (!value) {
            return '-';
          }

          return (
            <Box>
              <Text as="div">{value}</Text>
            </Box>
          );
        }
      },

      details: {
        name: 'details',
        label: 'Details',
        renderer: ({ value }) => {
          const { metrics } = value || {};

          if (metrics) {
            return (
              <Flex gap="4px" flexWrap="wrap">
                {metrics.map(({ metric, value: metricValue, severity }) => (
                  <Tag key={metric} fontWeight="bold" intent={healthToIntent({ health: severity })} minimal>
                    {metric}
                    {metricValue && `: ${metricValue}`}
                  </Tag>
                ))}
              </Flex>
            );
          }

          return '-';
        }
      },

      duration: {
        label: 'Duration',
        name: 'duration',
        wrapText: true,
        renderer: durationRenderer
      },

      end: {
        label: 'End',
        name: 'end_time',
        width: 150,
        renderer: endTimeRenderer
      },

      severity: {
        label: 'Severity',
        name: 'severity',
        width: minimal ? 50 : 100,
        renderer: severityRenderer({ minimal })
      },

      start: {
        label: 'Start',
        name: 'start_time',
        width: 150,
        renderer: ({ model, value }) => {
          const dateValue = dateTimeFormatter({ value });

          if (testResults) {
            // test results treatments, offer a link to change only the date range
            return renderTestResultsLink({
              $syn,
              model,
              value: dateValue,
              startTime: value,
              dateRangeOnly: true
            });
          }

          return dateValue;
        }
      },

      firstTrigger: {
        label: 'First Trigger',
        name: 'event_start_time',
        width: 150,
        renderer: ({ model, value }) => {
          const startTime = getFirstTriggerTime(value);

          if (startTime) {
            const dateValue = dateTimeFormatter({ value: startTime });

            if (testResults) {
              // test results treatments, offer a link to change only the date range
              return renderTestResultsLink({
                $syn,
                model,
                value: dateValue,
                startTime,
                dateRangeOnly: true
              });
            }

            return dateValue;
          }

          // legacy v1 alarms and v2 alarms before the 12/24 first trigger release get the N/A treatment
          return 'N/A';
        }
      },

      state: {
        label: 'State',
        name: 'state',
        width: minimal ? 50 : 80,
        renderer: alarmStateRenderer({ minimal })
      },

      target: {
        name: 'details.target',
        label: 'Target',
        renderer: ({ value }) => value || '-'
      },

      test: {
        id: 'test',
        label: 'Test',
        sortField: 'test',
        wrapText: true,
        renderer: ({ model }) => {
          const { testId, agentId, targetAgentId } = model.get('key.value') || {};
          const { target, resolver } = model.get('details') || {};

          if (!testId) {
            return '-';
          }

          const test = $syn.tests.get(testId);

          if (!test) {
            return testId;
          }

          const { testUrl, display } = getTestResultsLink({
            $syn,
            test,
            agentId,
            targetAgentId,
            target: test.isDns ? resolver : target,
            startTime: model.get('start_time'),
            endTime: model.get('end_time')
          });

          // supports bgp detailed info below
          const type = model.get('type');
          const details = model.get('details');
          const subtest = model.get('key.value.subtest');
          let errorContent = null;

          if (!!details && (type === 'bgp' || subtest)) {
            // v1 error content
            errorContent = (
              <Grid gridTemplateColumns="50px 1fr" gridGap={1}>
                <Text fontWeight="bold">Type:</Text>
                <Text>{details?.bgp?.display}</Text>
                <Text fontWeight="bold">Prefix:</Text>
                <Text>{details?.bgp?.prefix || '-'}</Text>
                <Text fontWeight="bold">Details:</Text>
                <Text>{details?.bgp?.reason}</Text>
              </Grid>
            );
          }

          return (
            <Flex gap={1}>
              <Link to={testUrl} fontWeight="bold">
                {display}
              </Link>
              <Text muted>({getTestTypeLabel(test.get('test_type'))})</Text>
              {errorContent && <TestErrorPopover error={errorContent} />}
            </Flex>
          );
        }
      }
    },

    utils: {
      groupByOptions: [
        { label: 'None', value: '' },
        { label: 'Severity', value: 'severity' },
        { label: 'State', value: 'state' }
      ].concat(
        testResults
          ? [
              { label: 'Agent', value: 'key.value.agentId' },
              { label: 'Target', value: 'details.target' }
            ]
          : [],

        bgp ? { label: 'Prefix', value: 'details.bgp.prefix' } : []
      ),

      groupSummaryLookup: ({ groupKey, group, groupBy }) => {
        // get a model from the group
        const model = group?.[0];
        const hasGroupKey = groupKey !== 'undefined'; // comes in as a string
        let renderedGroup;
        // const renderedLength = <Text>({group.length})</Text>;
        const renderedLength = <Tag minimal>Alarms: {group.length}</Tag>;

        switch (groupBy) {
          case 'key.value.agentId':
            renderedGroup = agentRenderer({ $syn })({ model });
            break;
          case 'severity':
            renderedGroup = severityRenderer({ minimal })({ model, value: model.get(groupBy) });
            break;
          case 'state':
            renderedGroup = alarmStateRenderer({ minimal })({ value: model.get(groupBy) });
            break;
          default:
            // just render the group key, falling back to a dash if necessary
            renderedGroup = <Text>{hasGroupKey ? groupKey : '-'}</Text>;
        }

        return (
          <Flex alignItems="center" gap={1}>
            {renderedGroup}
            {renderedLength}
          </Flex>
        );
      }
    }
  };
}

export function getCollectionConfig({ $syn, minimal = false, testResults = false, bgp = false, setPrefixes } = {}) {
  const {
    columns: {
      agent,
      alarm,
      bgpDisplay,
      bgpPrefix,
      bgpReason,
      details,
      duration,
      end,
      firstTrigger,
      severity,
      start,
      state,
      target,
      test
    },
    utils
  } = makeCollectionConfig({ $syn, minimal, testResults, bgp, setPrefixes });

  if (minimal) {
    // a compact set of columns, currently used in the smaller agent details "Alerts" tab view when in sidebar mode
    return { utils, columns: [severity, state, test, duration] };
  }

  if (bgp) {
    // return bgp-specific alarm columns
    return { utils, columns: [severity, state, bgpDisplay, bgpPrefix, bgpReason, firstTrigger, start, end] };
  }

  if (testResults) {
    // columns for incident log panel in test/agent results
    return { utils, columns: [severity, state, alarm, agent, target, details, firstTrigger, start, end] };
  }

  // the detault set used for main incident log
  return { utils, columns: [severity, state, alarm, test, firstTrigger, start, end] };
}
