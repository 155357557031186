import { pick } from 'lodash';
import { HEALTH } from 'shared/synthetics/constants';
import { getWorstHealth } from 'app/views/synthetics/utils/syntheticsUtils';
import { DENSITY, DEFAULT_FILTERED_METRICS } from './constants';

/*
  Determines the worst health and level of saturation for a given map of metrics

  This is used for styling in the grid where a saturation of:
  - none - no style
  - partial - circle background with worst health color
  - full - rectangle background with worst health color

  if there is no 'health' property on the metric, it will be logged as missing and get a muted background color
*/
function getSaturationSummary(metricsMap = {}) {
  // initialize the summary to be healthy and unsaturated
  const summary = { worstHealth: HEALTH.HEALTHY, saturation: 'none' };

  // get a list of metrics with health (or missing) and value
  const metrics = Object.values(metricsMap);

  if (metrics.length > 0) {
    // get the worst health of the bunch and the total count of them
    summary.worstHealth = metrics.reduce((worst, metric) => getWorstHealth(worst, metric?.health), HEALTH.HEALTHY);
    const worstHealthCount = metrics.filter((metric) => metric?.health === summary.worstHealth).length;

    if (summary.worstHealth !== HEALTH.HEALTHY && worstHealthCount > 0) {
      // update the saturation rate
      const saturation = worstHealthCount / metrics.length;

      if (saturation <= 0.5) {
        summary.saturation = 'partial';
      } else {
        summary.saturation = 'full';
      }
    }
  }

  return summary;
}

export function getMetricsToDisplay(metrics, health, resultTimeMs, filteredMetrics) {
  let metricsToDisplay = metrics;
  if (resultTimeMs) {
    const ts = (resultTimeMs / 1000).toString();
    const hd = health.find((healthEntry) => ts === healthEntry.time);
    metricsToDisplay = hd || {
      latency: {},
      loss: {},
      jitter: {}
    };

    if (filteredMetrics.length < 3) {
      metricsToDisplay = {
        time: metricsToDisplay.time,
        ...pick(metricsToDisplay, filteredMetrics)
      };
    }
  }
  const { time, ...rest } = metricsToDisplay || {};

  return {
    metrics: rest,
    saturationSummary: getSaturationSummary(rest)
  };
}

export function buildMesh(data, resultTimeMs, filteredMetrics) {
  return data.map((row, rowIndex) => {
    const columns = row.columns.map((col) => {
      const { metrics, health } = col;
      const { metrics: displayMetrics, saturationSummary } = getMetricsToDisplay(
        metrics,
        health,
        resultTimeMs,
        filteredMetrics
      );
      return { ...col, metrics: displayMetrics, saturationSummary };
    });

    return {
      ...row,
      index: rowIndex,
      columns: [...columns.slice(0, rowIndex), { empty: true }, ...columns.slice(rowIndex)].map((col, colIndex) => ({
        ...col,
        index: colIndex
      }))
    };
  });
}

/*
  Some canvas layers are omitted in certain density configurations such as:

  - text layers in max density
*/
export function isLayerRenderable({ density, type }) {
  if (type === 'text') {
    if (density.id === DENSITY.MAX.id) {
      // do not render text layers in max density grids
      return false;
    }
  }

  return true;
}

// returns a sorted list of metrics based on their default order
export function sortMetricValues(values = []) {
  return [...values].sort((a, b) => DEFAULT_FILTERED_METRICS.indexOf(a) - DEFAULT_FILTERED_METRICS.indexOf(b));
}
